<template>
  <div v-if="langLoaded" class="pa-5">
    <v-overlay v-if="loading">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
    <v-card
        class="mx-auto"
    >
      <v-card-title>
        <h2 v-t="'ProductsForm.ProductEditing'"></h2>
        <v-spacer></v-spacer>
        <v-btn color='secondary' v-t="'General.Return'" @click="$router.push({ name: 'Products' })"></v-btn>
      </v-card-title>
      <v-form v-model="valid" ref="form" class="pa-4">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
                v-model="currentProduct.name"
                :label="$t('ProductsForm.ProductNameDefault')"
                required
                outlined
                clearable
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-select
                v-model="currentProduct.categoryId"
                :items="categories"
                :label="$t('General.Categories')"
                item-text="name"
                item-value="id"
                clearable
                outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
                v-model="currentProduct.sku"
                :label="$t('ProductsForm.ProductSKUBase')"
                required
                clearable
                outlined
                @input="capsSku"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
                v-model="currentProduct.customCapacity"
                :label="$t('ProductsForm.ProductCapacity')"
                clearable
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
                v-model="currentProduct.productRef"
                label="Product Reference"
                clearable
                outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-card outlined>
          <v-card-title v-t="'General.ListTranslations'"></v-card-title>
          <v-card-text>
            <v-row class="align-center" v-for="lang in currentProduct.labelList" :key="lang.langValue">
              <v-col v-if="languageList && languageList.find((l) => { return l.value === lang.langValue })">
                <v-chip> {{
                    languageList.find((l) => {
                      return l.value === lang.langValue
                    }).country
                  }}
                </v-chip>
              </v-col>
              <v-col v-if="languageList && languageList.find((l) => { return l.value === lang.langValue })">
                <v-text-field
                    v-model="lang.label"
                    required
                    outlined
                    clearable
                    :label="$t('ProductsForm.ProductNameIn') + languageList.find((l) => { return l.value === lang.langValue }).country"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row>
          <v-col
          >
            <v-file-input @click:append="objComputed ? $store.dispatch('downloadFile', currentProduct.objPath) : null" :append-icon="objComputed ? 'mdi-download-circle' : null" color="primary" class="ma-5" v-model="inputFileObj" outlined
                          @change='showPrefixObj = false' :label="$t('ProductsForm.AddProduct')" :prefix="objComputed"/>
          </v-col>
        </v-row>
        <v-row>
          <v-col
          >
            <v-file-input @click:append="screenshotComputed ? $store.dispatch('downloadFile', currentProduct.screenshotPath) : null" :append-icon="screenshotComputed ? 'mdi-download-circle' : null" color="primary" class="ma-5" v-model="inputFileScreenshot" accept="image/*" outlined
                          @change='showPrefixScreenshot = false' :label="$t('ProductsForm.AddPreview')"
                          :prefix="screenshotComputed"/>
          </v-col>
          <v-col>
            <v-file-input @click:append="ftComputed ? $store.dispatch('downloadFile', currentProduct.ficheTechnique) : null" :append-icon="ftComputed ? 'mdi-download-circle' : null" color="primary" class="ma-5" v-model="inputFileFt" @change='showPrefixFt = false' outlined
                          accept="application/pdf" :label="$t('ProductsForm.AddTechnicalDataSheet')" :prefix="ftComputed"/>
          </v-col>
        </v-row>
      </v-form>
      <div class='float-right pa-3' style="display: flex; align-items: center">
        <v-switch
            style="margin-right: 30px"
            v-model="currentProduct.published"
            :label="$t('General.Publish')"
        ></v-switch>
        <v-btn color='primary' @click='addOrModifyProduct(false)' v-t="'General.Finish'"></v-btn>
        <v-btn style="margin-left: 5px" color='primary' @click='addOrModifyProduct(true)' v-t="'General.Continue'"></v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'ProductsForm',
  data () {
    return {
      stepnumber: 1,
      valid: false,
      langLoaded: false,
      newProduct: true,
      showPrefixFt: true,
      showPrefixObj: true,
      showPrefixScreenshot: true,
      loading: false,
      currentProduct: {
        id: '',
        name: '',
        labelList: [],
        impressions: [],
        index: '',
        colorList: [{
          name: 'default',
          color: '#FFFFFF',
          emissive: '#FFFFFF',
          sku: '00',
          preview: '#FFFFFF'
        }],
        categoryId: '',
        ficheTechnique: '',
        measureList: [],
        uvmapList: [],
        patronList: [],
        objPath: '',
        screenshotPath: '',
        sku: '',
        poids: 9999999,
        textures: [],
        lightList: [],
        cameraConfig: {
          position: {
            x: 0,
            y: 16,
            z: 29
          },
          distance: [30, 50],
          polarAngle: [3 / 12 * Math.PI, 6 / 12 * Math.PI],
          target: {
            x: 0,
            y: 7,
            z: 0
          }
        },
        otherEmissivesColors: [],
        otherColors: [],
        published: false
      },
      colors: ['Jaune', 'Vert'],
      categories: [],
      products: [],
      languageList: [],
      inputFileObj: {},
      inputFileSocle: {},
      inputFileFt: {},
      inputFileScreenshot: {}
    }
  },
  created () {
    this.getCategories()
    if (this.$route.params.id.indexOf('new') === -1) {
      this.getCurrentProduct()
      this.newProduct = false
    }
    this.$store.dispatch('getAllSites').then(() => {
      this.sites = this.$store.getters['GET_SITES']()
      this.languageList = []
      this.sites.forEach((site) => {
        site.languageList.forEach((langToAdd) => {
          if (!this.languageList.find((lang) => {
            return lang.value === langToAdd.value
          })) {
            this.languageList.push(langToAdd)
          }
        })
      })
      this.languageList.forEach((lang) => {
        if (!this.currentProduct.labelList.find((langLabel) => {
          return langLabel.langValue === lang.value
        })) {
          this.currentProduct.labelList.push({ langValue: lang.value, label: '' })
        }
      })
      this.langLoaded = true
    })
  },
  computed: {
    ftComputed () {
      return this.showPrefixFt ? this.getFileName(this.currentProduct.ficheTechnique) : ''
    },
    objComputed () {
      return this.showPrefixObj ? this.getFileName(this.currentProduct.objPath) : ''
    },
    screenshotComputed () {
      return this.showPrefixScreenshot ? this.getFileName(this.currentProduct.screenshotPath) : ''
    }
  },
  methods: {
    capsSku () {
      this.currentProduct.sku = this.currentProduct.sku.toUpperCase()
    },
    getFileName (name) {
      if (!name) return ''
      name = name.split('_')
      name.shift()
      return name.join('')
    },
    addObj (fileType) {
      if (!this.currentProduct.id) {
        return
      }
      var inputFile = {}
      var url = ''
      switch (fileType) {
        case 'ft':
          inputFile = this.inputFileFt
          url = 'TechnicalDetails'
          break
        case 'obj':
          inputFile = this.inputFileObj
          url = 'Object3D'
          break
        case 'screenshot':
          inputFile = this.inputFileScreenshot
          url = 'Preview'
      }

      if (!inputFile.name) {
        return
      }

      var reader = new FileReader()

      reader.onload = () => {
        var data = new FormData()
        var blob = new Blob([reader.result])
        data.append('fileName', inputFile.name.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
        data.append('id', this.currentProduct.id)
        data.append('fileType', fileType)
        data.append('file', blob)
        this.$store.dispatch('uploadProduct' + url, data)
      }
      reader.readAsArrayBuffer(inputFile)
    },
    getCurrentProduct () {
      this.$store.dispatch('getProduct', this.$route.params.id).then(() => {
        this.currentProduct = this.$store.getters['GET_CURRENT_PRODUCT']()
        if (this.currentProduct === null) {
          this.$emit('notify', {
            color: 'red',
            text: this.$t('ProductsForm.ProductNotFound')
          })
          this.$router.push({ name: 'Products' })
        }
      })
    },
    addOrModifyProduct (continuer) {
      // this.isSkuAvailable().then((res) => {
      //   if (res) {
          if (this.currentProduct.id) {
            this.modifyProduct(continuer)
          } else {
            this.addProduct(continuer)
          }
      //   } else {
      //     this.$emit('notify', {
      //       color: 'red',
      //       text: 'Ce SKU est déjà utilisé'
      //     })
      //   }
      // })
    },
    async addProduct (continuer) {
      if (!this.inputFileObj.name) {
        this.$emit('notify', {
          color: 'red',
          text: this.$t('ProductsForm.AddAnObject')
        })
        return
      }
      await this.$store.dispatch('getAllProducts')
      const products = this.$store.getters['GET_PRODUCTS']()
      this.currentProduct.index = products.length
      this.$store.dispatch('addProduct', this.currentProduct).then(() => {
        this.currentProduct = this.$store.getters['GET_CURRENT_PRODUCT']()
        this.addObj('ft')
        this.addObj('obj')
        this.addObj('screenshot')
        this.loading = true
        setTimeout(() => {
          continuer ? this.$router.push('/ThreeScene/' + this.currentProduct.id) : this.$router.push('/Products')
        }, 1000)
      }).catch((err) => {
        this.$emit('notify', {
          color: 'red',
          text: this.$t('Messages.' + err.response.data)
        })
      })
    },
    modifyProduct (continuer) {
      const data = {}
      data.product = this.currentProduct
      this.$store.dispatch('editProduct', data).then(() => {
        this.addObj('ft')
        this.addObj('obj')
        this.addObj('screenshot')
        this.loading = true
        setTimeout(() => {
          continuer ? this.$router.push('/ThreeScene/' + this.currentProduct.id) : this.$router.push('/Products')
        }, 1000)
      }).catch(() => {
        this.$emit('notify', {
          color: 'red',
          text: this.$t('Messages.ErrorOccured')
        })
      })
    },
    getCategories () {
      this.$store.dispatch('getAllCategories').then(() => {
        this.categories = this.$store.getters['GET_CATEGORIES']()
      })
    },
    getCategorieLabel (id) {
      var categorie = this.categories.find((category) => {
        return category.id === id
      })
      if (categorie) {
        return categorie.name
      }
    }
  }
}
</script>

<style scoped>

</style>
